//$fa-font-path: "~@fortawesome/fontawesome-free/webfonts/";
//@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
//@import "~@fortawesome/fontawesome-free/scss/solid.scss";
//@import "~@fortawesome/fontawesome-free/scss/regular.scss";
//@import "~@fortawesome/fontawesome-free/scss/brands.scss";

//@import '~bulma/bulma.sass';

@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/helpers/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/elements/title.sass";
@import "~bulma/sass/grid/columns.sass";

@import "hamburger-settings.scss";
@import "~hamburgers/_sass/hamburgers/hamburgers.scss";

@import "~animate.css/source/_vars.css";
@import "~animate.css/source/attention_seekers/headShake.css";

@import "~react-image-lightbox/style.css";
.ril__caption,
.ril__toolbar {
  background-color: transparent;
}

@import "dark-scrollbar.scss";
@import "animation.scss";
@import "slick-theme.scss";
@import "part.scss";

body {
  font-family: 'IBM Plex Serif', 'Noto Serif JP', serif;
}

body#ReactModal__Body--open {
  overscroll-behavior-y: none;
}

.noscroll {
  overflow-y: scroll;
  position: fixed;
  width: 100%;
}

div#root {
  overflow: hidden;
}

.limited-width {
  max-width: 1440px;
  margin: 0 auto;
  padding-left: calc(env(safe-area-inset-left));
  padding-right: calc(env(safe-area-inset-right));
}

// utils

.align-center {
  display: flex;
  flex-flow: column;
  align-items: center;
}

a.anchor {
  display: block;
  position: relative;
  top: -110px; // menu height
  visibility: hidden;
}
@media screen and (max-width: 1023.99px) {
  a.anchor {
    top: -100px;
  }
}

h1.hidden {
  display: none;
}

.fade-in {
  opacity: 0;
  transition: opacity 600ms ease-in-out;
}

.fade-in.active {
  opacity: 1;
}

.text {
  white-space: pre-wrap;
  word-wrap: break-word;
  // text-indent: 1em;
}

// bulma

.title {
  color: inherit;
  font-family: 'IBM Plex Serif', 'Noto Serif JP', serif;
}

.title.is-1 {
  color: white;
  font-size: 2.5rem;
}

.block:not(:last-child) {
  margin-bottom: 1.5rem;
}

.section {
  padding: calc(min(5vw, 5%)) calc(min(10vw, 10%));
}

@media screen and (max-width: 768.99px) {
  .is-hidden-mobile {
    display: none !important;
  }
}
@media screen and (min-width: 769px) {
  .is-hidden-tablet {
    display: none !important;
  }
}

@media screen and (max-width: 1023.99px) {
  .is-hidden-touch {
    display: none !important;
  }
}
@media screen and (min-width: 1024px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

// header

.header-loaded {
  margin-bottom: 10vh;
}

.header-loading-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  padding: 0%;
  background-color: #000000;
  z-index: 2;
}

.header-bottom-playbutton-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.header-bottom-playbutton {
  width: 10vh;
  height: 10vh;
  bottom: 0;
  position: absolute;
  opacity: 1;
  transition: opacity 250ms ease-in;
  cursor: pointer;
}

.content-mobile-playbutton {
  position: absolute;
  width: 30%;
  height: 30%;
  cursor: pointer;
  transition: opacity 250ms ease-in;
}

.header-bottom-playbutton.hidden {
  display: inherit;
  position: absolute;
  top: inherit;
  left: inherit;
  opacity: 0;
  transition: opacity 250ms ease-in;
}

.header-bottom-playbutton svg, .content-mobile-playbutton svg {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: scale(1.0);
  transition: transform 100ms ease-in;
  display: block;
}

.header-bottom-playbutton:hover svg, .content-mobile-playbutton:hover svg {
  transform: scale(1.05);
  transition: transform 100ms ease-in;
}

.header-bottom-playbutton:hover svg .circle, .content-mobile-playbutton:hover svg .circle {
  fill: #FFFFFF;
}

.header-bottom-playbutton:hover svg .play, .content-mobile-playbutton:hover svg .play {
  stroke: #000000;
  fill: #000000;
}

.header-bottom-scroll-indicator {
  position: absolute;
  bottom: 3.5vmin;
  right: 3.5vw;
  transform: translate(40%, -100%) rotate(90deg);
  user-select: none;
}

.header-bottom-scroll-indicator-text {
  font-size: 3vh;
  font-weight: 500;
  font-style: normal;
  color: #FFFFFF;
  line-height: 132%;
  white-space: pre-line;
}

// transition

.transition-scroll {
  position: absolute;
  width: 1%;
  top: 0px;
  height: 100vh;
  right: 1%;
}

.transition-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: -webkit-calc(max(100vh, 100%));
  z-index: 0;
  opacity: 0;
  background-color: #000000;
  pointer-events: none;
}

.transition {
  position: relative;
  background: linear-gradient(transparent 0%, black 100%);
  pointer-events: none;
}

.transition-building {
  position: relative;
  width: 100vw;
  right: 0;
  bottom: 0;
  transform: translate(0, 20%);
  z-index: 1;
  filter: drop-shadow(0px 0px 5px #000b1a);
}

// menu

@import "menu.scss";

// content

.content {
  color: #FFFFFF;
  position: relative;
  overflow: visible;
  background-color: black;
}

.content-foreground {
  position: relative;
  z-index: 1;
}

.content-building {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  z-index: 0;
}

.content-contact {
  display: flex;
  flex-flow: column;
}

.content-contact-head {
  flex: 0 1 auto;
}

.content-contact-body {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5% 0;
}

.content-about-title {
  position: relative;
  text-shadow: black 0 0 10px, black 0 0 10px, black 0 0 10px;
  margin-bottom: 1.5rem;
  z-index: 2;
}

.content-about-picture, .content-about-picture-mobile {
  user-select: none;
}

.content-about-picture-mobile {
  position: relative;
  z-index: -1;
  right: 0px;
  transform: scale(0.8);
}

svg.obake {
  margin: 5% 10%;
}

svg.obake:hover .head {
  animation: headShake;
  animation-duration: 1s;
}

svg.obake .tie {
  transition: all 250ms;
}

svg.obake:hover .tie {
  transition: all 250ms;
  transform: translate(0, 5%);
}

// credits

.credits {
  display: flex;
  flex-flow: column nowrap;
  z-index: 1;
  min-height: 40vw;
  text-shadow: black 0 0 10px, black 0 0 10px, black 0 0 10px;
  justify-content:center;
}

.credits-head {
  flex: 0 1 auto;
  font-weight: 500;
  font-size: 100%;
  margin-bottom: 1rem;
}
@media screen and (min-width: 769px) {
  .credits-head {
    margin-bottom: 2.6rem;
  }
}

.credits-body {
  flex: 0 1 auto;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content:center;
  align-content: center;
  z-index: 1;
  height: 100%;
}

.credits-section {
  display: flex;
  flex-flow: column nowrap;
  align-content:center;
  margin: 0 5%;
  padding: 0;
}

.credits-item, .credits-item-dummy {
  flex: 1 1 auto;
  font-size: 0.75rem;
  line-height: 240%;
  z-index: 1;
  text-align: left;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.credits-item-dummy {
  color: transparent;
  text-shadow: none;
  user-select: none;
}

// footer

.footer {
  color: #FFFFFF;
  position: relative;
  overflow: visible;
  background-color: black;
  padding: 0 10vw calc(max(1rem, 3vw)) 10vw;
}

.footer-copyright-text {
  width: 100%;
  text-align: center;
  display: inline-block;
  font-size: 0.75rem;
}